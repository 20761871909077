<template>
  <b-container>
		<b-row class="mb-3">
			<b-col>
				<b-card>
					<template #header>
						<h4>同意説明書</h4>
					</template>
          <div>ご参加いただける方は、必ず同意説明書をお読みください。</div>
					<a href="https://peerapp-study-docs.thinkss-kenkobus.net/consent-210906.pdf" target="_blank">https://peerapp-study-docs.thinkss-kenkobus.net/consent-210906.pdf</a>
				</b-card>
			</b-col>
		</b-row>
		<b-row class="mb-3">
			<b-col>
				<b-card>
					<template #header>
						<a name="privacy"><h4>個人情報取り扱いの方針について</h4></a>
					</template>
          <p>
            東海大学大学院医学研究科 臨床研究「特定保健指導積極的支援対象者に対するピアサポート型習慣化アプリによる行動変容の評価のためのランダム化比較試験(PEER-App Study)」（以下、本臨床研究）では、本臨床研究の運営にあたって事務局でお預かりした個人情報について、以下のとおり適正かつ安全に管理・運用することに努めます。
          </p>
          <p>
            １．利用目的<br>
            当社は、収集した個人情報について、以下の目的のために利用し、それ以外の目的では使用しません。<br>
            1.氏名とメールアドレス<br>
            ・本臨床研究に関する諸連絡（説明会のご案内、アンケートなど）<br>
            ・リモート実施にともなう研究同意取得の際のご本人様確認<br>
            ・相談・お問い合わせへの回答のため<br>
            2.送付先、氏名・住所・電話番号<br>
            ・測定機器および謝礼の発送のため<br>
          </p>
          <p>
            事務局窓口<br>
            〒259-1193<br>
            神奈川県伊勢原市下糟屋143<br>
            東海大学大学院医学研究科 ライフケアセンター<br>
            担当： 長張（ながはり）、苙口（おろぐち）
            <br>
            TEL：0463-93-1121 内線3769<br>
            E-mail：nagahari@tsc.u-tokai.ac.jp<br>
          </p>
				</b-card>
			</b-col>
    </b-row>
		<b-row class="mb-3">
			<b-col>
				<b-card>
					<template #header>
						<h4>ご参加について</h4>
					</template>
          <div>ご参加いただける方は、同意説明書をお読みいただき、説明会にご参加ください。</div>
          <div>それ以外の方のご参加は、ご遠慮ください。</div>
          <div class="m-3">
            <b-btn block variant="primary" size="lg" @click="onPost">ご参加いただける方は、このボタンを押して次へ進んでください</b-btn>
          </div>
				</b-card>
			</b-col>
		</b-row>
		<b-row class="pt-5 mb-3">
			<b-col>
				<b-card>
					<template #header>
						<h4>参加を取りやめるには</h4>
					</template>
          <div>本試験にご参加中の方で、参加を取りやめるには、以下の手順にしたがってください。</div>
          <div>XXXXX</div>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>

export default {
  name: 'Form',
  data () {
    return {
      loading: false,
      message: null,
      error: null
    }
  },
  methods: {
    onPost () {
      this.$router.push('/consent/form')
    }
  }
}
</script>
